import React                                        from 'react';
import ImageGallery                                 from 'react-image-gallery';
import InnerImageZoom                               from 'react-inner-image-zoom';
import { Img }                                      from 'react-image';
import { IconButton, Skeleton, useBreakpointValue } from '@chakra-ui/react';
import { IoIosArrowBack, IoIosArrowForward }        from 'react-icons/io';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import { endpoints } from '../../config';
import './styles.css';

const Thumbnail = ({ image }) => {
  return <Img
    src={image.thumbnail}
    alt=""
    unloader={<img src="https://placehold.jp/333/ffffff/100x100.png?text=Kein%20Bild" alt="Kein Bild"/>}
    loader={<Skeleton height="100px"/>}/>
}

const Nav = ({ direction, onClick }) => {
  const size = useBreakpointValue({ sm: 'sm', md: 'md', lg: 'lg' })
  return <IconButton
    sx={{
      position: 'absolute',
      top: '45%',
      zIndex: '2',
      right: direction === 'right' && '-50px',
      left: direction === 'left' && '-50px',
      margin: '0 20px',
      opacity: 0.4,
      ':focus': {
        opacity: 1,
      },
      '.image-gallery-slide-wrapper:hover &': {
        opacity: 1,
      },
    }}
    size={size}
    aria-label={direction === 'right' ? 'Nächtes Bild' : 'Vorheriges Bild'}
    isRound
    onClick={onClick}
    icon={direction === 'right' ? <IoIosArrowForward/> : <IoIosArrowBack/>}
  />
}

const buildImageArray = (images) => {
  const arr = [];
  images.filter(image => !!image.file_id).forEach(image => {
    const struc = {
      original: `${endpoints.thumbnails}/${image.file_id.private_hash}?key=e-detail-slideshow`,
      thumbnail: `${endpoints.thumbnails}/${image.file_id.private_hash}?key=e-detail-thumbnail`,
      zoom: `${endpoints.thumbnails}/${image.file_id.private_hash}?key=e-detail-zoom`,
      enableZoom: image.height > 2048 || image.width > 2048
    };
    arr.push(struc)
  });
  return arr;
};

// TODO get correct color for placeholder from theme
const Gallery = ({ images }) => {
  const galleryOptions = {
    lazyLoad: true,
    showFullscreenButton: false,
    showPlayButton: false,
    defaultImage: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    onErrorImageURL: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    renderThumbInner: image => <Thumbnail image={image}/>,
    renderRightNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="right"/>,
    renderLeftNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="left"/>,
    renderItem: (p) => {
      if (p.enableZoom) {
        return (<InnerImageZoom
          src={p.original}
          zoomSrc={p.zoom}/>)
      } else {
        return <Img
          src={p.original}
          alt=""
          unloader={<img src="https://placehold.jp/333/ffffff/1500x1500.png?text=Kein%20Bild" alt="Kein Bild"/>}
          loader={<Skeleton height="500px"/>}/>
      }
    }
  };

  if (images.length > 1) {
    return <ImageGallery items={buildImageArray(images)} {...galleryOptions}/>
  } else if (images.length === 1) {
    const image = images.filter(image => !!image.file_id)[0].file_id;
    if (image.height < 2048 || image.width < 2048) {
      return <Img
        src={`${endpoints.thumbnails}/${images.filter(image => !!image.file_id)[0].file_id.private_hash}?key=e-detail-slideshow`}
        alt=""
        unloader={<img src="https://placehold.jp/333/ffffff/1500x1500.png?text=Kein%20Bild" alt="Kein Bild"/>}
        loader={<Skeleton height="500px"/>}/>
    } else {
      return <InnerImageZoom
        src={`${endpoints.thumbnails}/${image.private_hash}?key=e-detail-slideshow`}
        zoomSrc={`${endpoints.thumbnails}/${image.private_hash}?key=e-detail-zoom`}
        fullscreenOnMobile={true}
      />
    }
  } else {
    return <img src="https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild" alt="Kein Bild"/>
  }
};

export default Gallery;