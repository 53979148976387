import React, { useContext, useState, useEffect } from 'react';
import PropTypes                                  from 'prop-types';
import { PayPalButtons }                          from '@paypal/react-paypal-js';

import { useContact }                from '@interness/web-core/src/hooks/useContact';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

import { StoreContext } from '../../context/Store';
import useTaxValue      from '../../hooks/useTaxValue';
import { EApiContext }  from '../../context/EApi';

const PayPal = ({ label, disableCheckId, action, order: orderData, setApproved }) => {
  const contact = useContact();
  const [loading, setLoading] = useState(false);
  const [shippingCountries, setShippingCountries] = useState([]);
  const [showError, setShowError] = useState(false);
  const { settings, projectName } = useContext(StoreContext);
  const { onPayPalTransaction } = useContext(EApiContext);
  const { priceBrutto: itemsTotal, taxValue: itemsTotalTax, priceNetto: itemsTotalNetto } = useTaxValue(orderData.total_brutto);

  const addresses = {
    shipping: orderData.customer.addresses.filter(address => address.address_type === 'shipping')[0],
    invoice: orderData.customer.addresses.filter(address => address.address_type === 'invoice')[0],
  }

  useEffect(() => {
    if (settings.data) {
      const arr = [];
      settings.data.shipping_countries.map(country => arr.push(country.country_id.country_code));
      setShippingCountries(arr);
    }
  }, [settings])

  const handleApprove = async (paypal) => {
    const res = await onPayPalTransaction(orderData.id, projectName, paypal);
    if (res.status === 'ok') {
      setLoading(false);
      setApproved(true);
      return false;
    } else {
      setLoading(false);
      return false;
      // handle fail...
    }
  }

  const onInit = (data, actions) => {
    const elem = document.getElementById(disableCheckId);
    if (disableCheckId && !elem.checked) {
      actions.disable();
      elem.addEventListener('change', e => {
        if (e.target.checked) {
          actions.enable();
        } else {
          actions.disable()
        }
      })
    }
  };

  const onClick = () => {
    if (disableCheckId) {
      if (!document.getElementById(disableCheckId).checked) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  }

  const onCreatePayPalOrder = async (data, actions) => {
    setLoading(true);
    const order = {
      intent: action === 'CONTINUE' ? 'AUTHORIZE' : 'CAPTURE',
      application_context: {
        brand_name: contact.company_name,
        shipping_preference: orderData.shipping_id === 1 ? 'NO_SHIPPING' : 'SET_PROVIDED_ADDRESS',
      },
      purchase_units: [{
        custom_id: orderData.uuid,
        amount: {
          currency_code: 'EUR',
          value: itemsTotal + orderData.shipping_cost,
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: itemsTotalNetto,
            },
            tax_total: {
              currency_code: 'EUR',
              value: itemsTotalTax,
            },
            shipping: {
              currency_code: 'EUR',
              value: orderData.shipping_cost,
            },
          },
        }
      }],
    }
    if (orderData.shipping_id !== 1) {
      order.purchase_units[0].shipping = {
        name: {
          full_name: `${addresses.shipping.name} ${addresses.shipping.surname}`
        },
        address: {
          address_line_1: addresses.shipping.street,
          address_line_2: addresses.shipping.addition,
          admin_area_2: addresses.shipping.city,
          postal_code: addresses.shipping.zip,
          country_code: 'DE'
        },
      }
    }
    try {
      return actions.order.create(order);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const onShippingChange = (data, actions) => {
    if (!shippingCountries.includes(data.shipping_address.country_code)) {
      return actions.reject()
    }
    return actions.resolve();
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async details => {
      if (details.status === 'COMPLETED') {
        return await handleApprove(details)
      }
    })
  }

  const onCancel = () => {
    console.log('Cancel')
    setLoading(false);
  };

  const onError = () => {
    console.log('Error')
    setLoading(false);
  };

  return (
    <>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      <div style={{ opacity: loading ? '0' : '1' }}>
        <PayPalButtons
          style={{
            label: label
          }}
          onInit={onInit}
          onClick={onClick}
          createOrder={onCreatePayPalOrder}
          onApprove={onApprove}
          onCancel={onCancel}
          onError={onError}
          onShippingChange={onShippingChange}
          forceReRender={orderData}
        />
        {showError && <p>Akzeptieren Sie zunächst die AGB und Widerrufsbelehrung</p>}
      </div>
    </>
  )

};

PayPal.propTypes = {
  action: PropTypes.oneOf(['CONTINUE', 'PAY_NOW']),
  label: PropTypes.string,
  order: PropTypes.object.isRequired,
}

PayPal.defaultProps = {
  action: 'CONTINUE',
  label: null,
}

export default PayPal;