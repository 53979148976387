import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const Form = styled.form`
  margin-bottom: 0;

  .hidden {
    display: none;
  }

  label {
    display: block;
    text-align: left;
  }

  input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
    color: ${props => readableColor(props.theme.colors.base['500'])};
  }

  textarea {
    width: 100%;
    height: 130px;
    padding: 5px 10px;
    resize: none;
    border-color: rgb(204, 204, 204);
    color: ${props => readableColor(props.theme.colors.base['500'])};
  }

  div > div {
    margin-bottom: 8px;
  }

  .int-legal-checkbox-label {
    display: block;

    > input, p {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .int-btn-group {
    button {
      margin-left: 10px;
    }
  }
`;