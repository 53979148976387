import styled from '@emotion/styled';

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
`;

export const Row = styled.li`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px 0;
  &:nth-child(even) {
    background-color: var(--chakra-colors-base-400);
  }
`;

export const RemoveButton = styled.button`
  border: none;
  background: unset;
  cursor: pointer;
  svg {
    vertical-align: text-top;
  }
`;