import React, { useState, useContext } from 'react';
import { Container, Row, Col }         from 'react-grid-system';
import { useFormState }                from 'react-use-form-state';
import cogoToast                       from 'cogo-toast';
import {
  Button,
  Input,
  FormLabel,
  Textarea, Checkbox
}                                      from '@chakra-ui/react';

import Link from '@interness/web-core/src/components/elements/Link/Link';

import * as S          from './styles';
import { EApiContext } from '../../context/EApi';

const RequestForm = ({ product, cta }) => {
  const [loading, setLoading] = useState(false);
  const { createRequest } = useContext(EApiContext)

  const [formState, { text, email, checkbox, number, textarea, label }] = useFormState({
    product: product.id
  }, {
    withIds: true
  });

  const reset = () => {
    formState.reset()
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    let formValues = {
      ...formState.values
    };
    delete formValues.acceptLegal;
    const req = await createRequest(formValues);
    if (!req.error) {
      setLoading(false);
      cogoToast.success('Anfrage erfolgreich versendet.', {
        position: 'bottom-center',
        hideAfter: 5,
        heading: 'Erfolg'
      })
      reset();
    } else {
      setLoading(false);
      cogoToast.error('Fehler beim Versenden der Anfrage. Bitte versuchen Sie es später erneut.', {
        position: 'bottom-center',
        hideAfter: 5,
        heading: 'Fehler'
      })
    }
  };

  const handleReset = () => {
    reset();
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {cta
        ? <div dangerouslySetInnerHTML={{ __html: cta }}/>
        : <div>
          <p>Liebe Kundinnen und Kunden.</p>
          <p>Haben Sie etwas Schönes in unserem virtuellen Schaufenster gefunden? Suchen Sie ein passendes Geschenk für
            Ihre Liebsten?</p>
        </div>
      }
      <S.Form
        id="int-item-request"
        name="display-item-request"
        method="POST"
        onReset={handleReset}
        onSubmit={handleSubmit}>
        <Container fluid>
          <div className="hidden">
            {/*eslint-disable-next-line*/}
            <label hidden {...label('product')}>ID</label>
            <input type="hidden" {...number('product')} required readOnly/>
          </div>
          <Row>
            <Col md={12}>
              {/*eslint-disable-next-line*/}
              <FormLabel {...label('name')}>Name*</FormLabel>
              <Input variant="outline" {...text('name')} placeholder="Max Mustermann" required/>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/*eslint-disable-next-line*/}
              <FormLabel {...label('email')}>Email*</FormLabel>
              <Input variant="outline" {...email('email')} placeholder="max@mustermann.de" required/>
            </Col>
            <Col md={6}>
              {/*eslint-disable-next-line*/}
              <FormLabel {...label('phone')}>Telefon</FormLabel>
              <Input variant="outline" {...number('phone')} placeholder="012345"/>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/*eslint-disable-next-line*/}
              <FormLabel {...label('message')}>Nachricht*</FormLabel>
              <Textarea {...textarea('message')} placeholder="Ich interessiere mich für o.g. Produkt..."
                        required/>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <label {...label('acceptLegal')} className="int-legal-checkbox-label">
                <Checkbox colorScheme="brand" {...checkbox('acceptLegal')}/>
                <p>*Ja, ich habe die <Link to="/datenschutz" target="_blank">Datenschutzerklärung</Link> zur
                  Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch
                  erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur
                  Bearbeitung und Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars
                  erkläre ich mich mit der Verarbeitung einverstanden.</p>
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: 'right' }}>
                <div className="int-btn-group">
                  <Button colorScheme="brand" variant="ghost" type="reset">Abbrechen</Button>
                  <Button colorScheme="brand" type="submit"
                          disabled={(!formState.values.acceptLegal || loading) || (!formState.values.name || !formState.values.email || !formState.values.message)}>Abschicken</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <p style={{ textAlign: 'right', fontSize: '0.8rem' }}>* Pflichtfelder</p>
      </S.Form>
      {/*<p>Irrtümer, Änderungen und Zwischenverkauf vorbehalten. Alle Angaben ohne Gewähr.</p>*/}
    </div>
  )
};

export default RequestForm;