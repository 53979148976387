import React from 'react';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Center  from '@interness/web-core/src/components/structure/Center/Center';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

const MaintenanceModePage = () => {
  return (
    <Wrapper>
      <Spacer/>
      <Center>
        <h1>Wir sind gleich zurück...</h1>
        <p>Wir aktualisieren gerade unseren Shop. <br/>
          Bitte versuchen Sie es später erneut.
        </p>
      </Center>
      <Spacer/>
    </Wrapper>
  )
};

export default MaintenanceModePage;