import React, { useContext, useState } from 'react';
import { FormattedDate }               from 'react-intl';
import { useQuery }                    from 'react-query';
import Rating                          from 'react-rating';
import { FaStar, FaRegStar }           from 'react-icons/fa';
import { useFormState }                from 'react-use-form-state';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Button,
  Input,
  FormLabel,
  Textarea,
  Flex,
  Box,
  useToast
}                                      from '@chakra-ui/react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import { EApiContext } from '../../context/EApi';
import Error           from '../Error/Error';

import * as S      from './styles';
import RatingStars from './RatingStars';

const ProductRatings = ({ productId }) => {
  const toast = useToast();
  const { fetchProductRatings, createProductRating } = useContext(EApiContext);
  const [currentRating, setCurrentRating] = useState(5);
  const [loading, setLoading] = useState(false);
  const [formState, { email, label, text, textarea }] = useFormState({}, {
    withIds: true,
  });
  const { isIdle, isLoading, isError, data } = useQuery(['product_rating', {
    id: productId
  }], fetchProductRatings, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });

  const onRate = async () => {
    setLoading(true);
    const rating = {
      ...formState.values,
      product: productId,
      rating: currentRating
    }
    const res = await createProductRating(rating);
    if (!res.error) {
      formState.reset();
      setCurrentRating(5);
      setLoading(false);
      toast({
        title: 'Bewertung erfolgreich abgegeben.',
        description: 'Warten auf Freigabe',
        status: 'success',
        isClosable: true,
      })
    } else {
      setLoading(false);
      toast({
        title: 'Bewertung konnte nicht gespeichert werden.',
        description: 'Bitte versuchen Sie es später erneut',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const onChangeRating = (newRating) => {
    setCurrentRating(newRating);
  }

  if (isIdle || isLoading) {
    return <SpinnerContainer><Spinner/></SpinnerContainer>
  }

  if (isError) {
    return <Error>Bewertungen konnten nicht geladen werden</Error>
  }

  const ratings = data.data;

  return (
    <div>
      {ratings.length === 0 && <Heading as="h3" size="lg">Noch keine Bewertungen</Heading>}
      {ratings.length === 1 && <Heading as="h3" size="lg">Eine Bewertung</Heading>}
      {ratings.length >= 2 && <Heading as="h3" size="lg">{ratings.length} Bewertungen</Heading>}
      <Spacer height={20}/>
      {ratings.length !== 0 && <Accordion allowToggle>
        {ratings.map(rating => (
          <AccordionItem key={rating.id}>
            <h4>
              <AccordionButton>
                <Flex alignItems="center" justifyContent="space-between" flexWrap='wrap' w='100%'>
                  <Box textAlign="left">
                    <i><FormattedDate value={rating.created_on}
                                      hour12={false}
                                      year="numeric"
                                      month="long"
                                      day="2-digit"/></i><br/>
                    <b>{rating.name}</b>
                  </Box>
                  <Box alignSelf='flex-end'>
                    <RatingStars rating={rating.rating} />
                    <AccordionIcon/>
                  </Box>
                </Flex>
              </AccordionButton>
            </h4>
            <AccordionPanel>
              {rating.comment
                ? <p>{rating.comment}</p>
                : <i>Kein Kommentar abgegeben.</i>
              }
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>}
      <Spacer/>
      <Heading as="h4" size="md">Bewertung abgeben</Heading>
      <Spacer height={20}/>
      <div>
        <FormLabel>Bewertung*</FormLabel>
        <S.Stars>
          <Rating
            initialRating={currentRating}
            emptySymbol={<FaRegStar/>}
            fullSymbol={<FaStar/>}
            onChange={onChangeRating}
          />
        </S.Stars>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Box width={{ base: '100%', lg: '48%' }} my={5}>
            <FormLabel {...label('name')}>Name*</FormLabel>
            <Input {...text('name')} variant="filled" isRequired/>
          </Box>
          <Box width={{ base: '100%', lg: '48%' }} my={5}>
            <FormLabel {...label('email')}>E-Mail*</FormLabel>
            <Input {...email('email')} variant="filled" isRequired/>
          </Box>
          <Box w="100%" mt={5}>
            <FormLabel {...label('comment')}>Kommentar</FormLabel>
            <Textarea {...textarea('comment')} variant="filled" rows={4}/>
          </Box>
        </Flex>
        <Box textAlign="right">
          <Button colorScheme="brand" size="sm" onClick={onRate} isLoading={loading}
                  isDisabled={!formState.values.name || !formState.values.email} mt={10}>Bewertung abgeben</Button>
        </Box>
      </div>
    </div>
  )

}
;

export default ProductRatings;
