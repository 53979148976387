import styled    from '@emotion/styled';
import PropTypes from 'prop-types';

const Separator = styled.div`
  width: ${props => props.width};
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  margin: ${({ spacing }) => spacing}px auto;
  display: block;
`;

Separator.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  spacing: PropTypes.number,
  width: PropTypes.string,
};

Separator.defaultProps = {
  //TODO THEME?!
  color: '#efefef',
  height: 1,
  spacing: 40,
  width: '100%',
};

export default Separator;
