import React, { useContext }    from 'react';
import { Router, navigate }     from '@reach/router';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import ConditionalWrapper
                                     from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import SEO                           from '@interness/web-core/src/components/modules/SEO/SEO';

import Overview              from '../components/Overview/Overview';
import ProductPage           from '../templates/ProductPage/ProductPage';
import NotFound              from '../templates/NotFound';
import CartPage              from '../templates/CartPage';
import CheckoutPage          from '../templates/CheckoutPage';
import { StoreContext }      from '../context/Store';
import OrderConfirmationPage from '../templates/OrderConfirmationPage';
import Error                 from '../templates/Error';
import MaintenanceModePage   from '../templates/MaintenanceModePage';

const DisplayPage = (props) => {
  const { display_name, path } = props.pageContext.display_config;
  const { settings, paypal } = useContext(StoreContext);

  const paypalOptions = {
    'client-id': process.env.NODE_ENV === 'development' ? 'AQu2ERqY0sW-89_oMnw8FHj6rnWOPQAeRu6RUrot9aXmS2AkwgemDager8dkiL4Q2FRT2rxBk3y70aOQ' : paypal,
    intent: 'capture',
    components: 'buttons',
    currency: 'EUR',
    'disable-funding': 'card,sofort,sepa,giropay,credit,bancontact,blik,eps,ideal,mercadopago,mybank,p24,venmo',
    // debug: process.env.NODE_ENV !== 'production'
  }

  if (settings.error) {
    navigate('/' + path + '/error');
  }

  if (settings.loading) {
    return <><Spacer/><SpinnerContainer><Spinner/></SpinnerContainer><Spacer/></>
  }

  if (settings.data && settings.data.maintenance_mode) {
    return <MaintenanceModePage />
  }

  return (
    <>
      <SEO title='Shop' description='Shop'/>
      <ConditionalWrapper condition={paypal} wrapper={children => <PayPalScriptProvider
        options={paypalOptions}>{children}</PayPalScriptProvider>}>
        <Router basepath={`/${path}`}>
          <Overview title={display_name} path={`/`}/>
          <Overview title={display_name} path={`/tag/:tag`}/>
          <ProductPage path={`/:category/:name/:id`}/>
          <ProductPage path={`/:id`}/>
          {!settings.loading && settings.data && settings.data.enable_cart && <>
            <CartPage path={`/warenkorb`}/>
            <CheckoutPage path={`/kasse`}/>
            <OrderConfirmationPage path={`/bestellung/:uuid`}/>
          </>}
          <Error path={`/error`}/>
          <NotFound default/>
        </Router>
      </ConditionalWrapper>
      <>{settings.data && settings.data.global_footer && <div dangerouslySetInnerHTML={{ __html: settings.data.global_footer }}/>}</>
    </>
  );
};

export default DisplayPage;
