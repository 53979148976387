import React, { useContext } from 'react';
import { Link }              from '@reach/router';
import { Flex, Tag }               from '@chakra-ui/react';

import { StoreContext }  from '../../context/Store';

const Tags = ({ tags }) => {
  const { storeRootPath } = useContext(StoreContext);
  return (
    <Flex flexWrap='wrap'>
      {tags.map(tag => <Link key={tag} to={`${storeRootPath}/tag/${encodeURI(tag)}`}><Tag m={1}>{tag}</Tag></Link>)}
    </Flex>
  )
};

export default Tags;