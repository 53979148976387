import React, { useContext, useState, useEffect } from 'react';
import { Button, Heading, Radio, RadioGroup }     from '@chakra-ui/react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import { CheckoutContext } from '../../context/Checkout';
import Address             from '../Address/Address';

const CheckoutPayment = ({ methods, nextStep, previousStep, shippingCountries }) => {
  const {
    paymentMethod,
    setPaymentMethod,
    shippingMethod,
    setInvoiceAddress,
    shippingAddress,
  } = useContext(CheckoutContext);
  const [filteredMethods, setFilteredMethods] = useState(null);
  const [customInvoiceAddress, setCustomInvoiceAddress] = useState(false);
  // const [payment, setPayment] = useState(null);

  const onSubmit = async () => {
    if (!customInvoiceAddress) {
      const localShipping = { ...shippingAddress };
      localShipping.address_type = 'invoice'
      await setInvoiceAddress(localShipping)
    }
    if (!paymentMethod) {
      setPaymentMethod(filteredMethods[0].payment_id);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    nextStep();
  };

  const onChange = (value) => {
    // setPayment(JSON.parse(value));
    setPaymentMethod(JSON.parse(value));
  }

  const onCustomInvoiceAddressChange = (value) => {
    const isTrue = value === 'true';
    setCustomInvoiceAddress(!!isTrue);
  }

  const filterMethods = async () => {
    try {
      if (shippingMethod && shippingMethod.id === 1) {
        await setFilteredMethods(methods);
        // await setPaymentMethod(filteredMethods[0].payment_id);
      } else {
        const filtered = await methods.filter(method => method.payment_id.id !== 1)
        await setFilteredMethods(filtered);
        // await setPayment(methods[0].payment_id);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (methods) {
        filterMethods();
      }
      if (!paymentMethod && filteredMethods) {
        // setPayment(filteredMethods[0])
        setPaymentMethod(filteredMethods[0].payment_id)
      }
    }
    return () => {
      mounted = false;
    }
    //eslint-disable-next-line
  }, [shippingMethod, methods, paymentMethod])

  return (
    <div style={{ maxWidth: '90%', margin: 'auto' }}>
      <Heading as="h2">Zahlung</Heading>
      <Spacer height={20}/>
      <RadioGroup value={JSON.stringify(paymentMethod)} onChange={onChange}
                  defaultValue={JSON.stringify(paymentMethod)}>
        {filteredMethods && filteredMethods.map(method => {
          return (
            <div key={method.payment_id.id}
                 style={{ padding: '10px', border: '1px solid lightgray', marginBottom: '10px', borderRadius: '3px' }}>
              <Radio name="payment_method" colorScheme="brand"
                     id={method.payment_id.display_name}
                     value={JSON.stringify(method.payment_id)}>
                <span>{method.payment_id.display_name}</span>
              </Radio>
            </div>
          );
        })}
      </RadioGroup>
      {shippingMethod.id !== 1 && <div>
        <Heading as="h2">Rechnungsadresse</Heading>
        <Spacer height={20}/>
        <RadioGroup name="customInvoiceAddress" value={customInvoiceAddress.toString()} defaultValue={customInvoiceAddress.toString()}
                    onChange={onCustomInvoiceAddressChange}>
          <div style={{ padding: '10px', border: '1px solid lightgray', marginBottom: '10px', borderRadius: '3px' }}>
            <Radio id="no-custom-address"
                   value="false"
                   colorScheme="brand">
              Rechnungsadresse identisch mit Lieferadresse
            </Radio>
          </div>
          <div style={{ padding: '10px', border: '1px solid lightgray', marginBottom: '10px', borderRadius: '3px' }}>
            <Radio id="custom-address"
                   value="true"
                   colorScheme="brand">
              Neue Rechnungsadresse eingeben
            </Radio>
          </div>
        </RadioGroup>
        {customInvoiceAddress && <div>
          <Address addressType="invoice" shippingCountries={shippingCountries}/>
        </div>}
        <Spacer height={20}/>
      </div>}
      <div style={{ margin: 'auto' }}>
        <Button colorScheme="brand" variant="solid" width="100%" onClick={onSubmit}>Weiter</Button>
        <Spacer height={20}/>
        <Button colorScheme="brand" variant="outline" width="100%" onClick={previousStep}>Zurück</Button>
      </div>
    </div>
  )
}
;

export default CheckoutPayment;