import styled from '@emotion/styled';

export const Form = styled.form`
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  button {
    max-width: 90%;
    margin: auto;
  }
  label {
    user-select: none;
  }
  a {
    text-decoration: underline;
  }
  //input, select {
  //  max-height: 45px;
  //}
  //input, select, textarea {
  //  width: 100%;
  //  margin: 2px 0 10px 0;
  //  padding: 10px 10px;
  //  background-color: rgb(247,247,247);
  //  border-radius: 3px;
  //  border: 1px solid rgb(196,196,196);
  //  &.valid {
  //    border: 2px solid #27ae60;
  //  }
  //  &[type='checkbox'] {
  //    width: unset;
  //    margin-right: 15px;
  //  }
  //  &:focus {
  //    background-color: rgba(247,247,247, 1);
  //  }
  //  &:valid {
  //    background-color: rgba(247,247,247, 0.8);
  //  }
  //}
  > div {
    margin-bottom: 30px;
  }
`;