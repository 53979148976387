import React, { useState, useEffect } from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper, useTheme,
}                                     from '@chakra-ui/react';
import { readableColor }              from 'polished';

const CountSelector = ({ isInCart, productStock, countInCart, currentCount, onChangeCount, disabled, cartMode }) => {
  const [countAvailable, setCountAvailable] = useState(productStock);
  const theme = useTheme();

  const changeCountAvailable = (newCount) => {
    if (Math.sign(newCount) !== -1) {
      setCountAvailable(newCount)
    }
  }

  useEffect(() => {
    if (isInCart && !cartMode) {
      changeCountAvailable(parseInt(productStock) - countInCart);
    } else {
      changeCountAvailable(parseInt(productStock));
    }

  }, [isInCart, cartMode, countInCart, productStock])

  return (
    <div>
      <NumberInput size="md"
                   mr={5}
                   mt={5}
                   width="auto"
                   maxWidth={20}
                   display="inline-block"
                   aria-label="Anzahl"
                   defaultValue={1}
                   value={currentCount}
                   min={1}
                   max={countAvailable}
                   sx={{
                     input: {
                       color: `${readableColor(theme.base_color)} !important`
                     }
                   }}
                   onBlur={e => onChangeCount(e.target.value, isInCart.cartId)}
                   isDisabled={!countAvailable || disabled}
      >
        <NumberInputField/>
        <NumberInputStepper>
          <NumberIncrementStepper/>
          <NumberDecrementStepper/>
        </NumberInputStepper>
      </NumberInput>
    </div>
  )
};

CountSelector.defaultProps = {
  cartMode: false,
}

export default CountSelector;