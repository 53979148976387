import React, { useState, useEffect, useContext } from 'react';
import { useQuery }                               from 'react-query';
import { queryCache }                             from 'react-query'
import cogoToast                                  from 'cogo-toast';
import currency                                   from 'currency.js';
import { Flex, Box }                              from '@chakra-ui/react';

import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import { useContact }                from '@interness/web-core/src/hooks/useContact';

import { endpoints }    from '../config';
import PayPal           from '../components/PayPal/PayPal';
import { StoreContext } from '../context/Store';
import PriceTag         from '../components/PriceTag';
import Cart             from '../components/Cart/Cart';
import Klarna           from '../components/Klarna/Klarna';

const fetchOrder = async (name, { uuid, project }) => {
  const res = await fetch(`${endpoints.orderApi}/ecom/order/${uuid}?project=${project}`)
  return await res.json();
};

const OrderConfirmationPage = (props) => {
  const contact = useContact();
  const [order, setOrder] = useState(null);
  const [addresses, setAddresses] = useState({});
  const [shippingCost, setShippingCost] = useState(0);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [payPalOrder, setPayPalOrder] = useState(null);
  const { paypal, settings, projectName } = useContext(StoreContext);
  const { data: settingsData } = settings;

  const { isLoading, data, error } = useQuery(['order', {
    uuid: props.uuid,
    project: projectName
  }], fetchOrder, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      if (data.error || error) {
        setOrder(data || error);
      } else {
        setShippingCost(data.data.shipping_method.free ? 0 : settingsData.shipping_cost);
        setOrder(data.data);
        setAddresses({
          shipping: data.data.customer.addresses.filter(address => address.address_type === 'shipping')[0],
          invoice: data.data.customer.addresses.filter(address => address.address_type === 'invoice')[0],
        })
      }
    }
    if (order && order.status === 'open') {
      const orderStruc = {
        id: order.id,
        uuid: order.uuid,
        total_brutto: order.total_brutto,
        shipping_cost: shippingCost,
        shipping_id: order.shipping_method.id,
        customer: order.customer
      }
      setPayPalOrder(orderStruc);
    }
    //eslint-disable-next-line
  }, [error, isLoading, paypal, order, data])

  const onApprove = async (approved) => {
    setPaymentApproved(approved);
    await queryCache.invalidateQueries('order');
    await queryCache.refetchQueries(['order', {
      uuid: props.uuid
    }]);
    cogoToast.success('Wir halten Sie per E-Mail auf dem Laufenden zur Ihrer Bestellung.', {
      heading: 'Zahlung erfolgreich.',
      position: 'bottom-center',
      hideAfter: 5,
    });
  };

  const statusMap = {
    open: 'Offen',
    paid: 'Bezahlt',
    pickup_ready: 'Zur Abholung bereit',
    shipped: 'Verschickt'
  }

  return (
    <Wrapper>
      <Spacer/>
      {order && order.error && <Heading>Bestellung nicht gefunden</Heading>}
      {order && !order.error && <Heading subtitle={order.uuid}>Bestellung</Heading>}
      <Spacer/>
      {order && order.error && <p>Bestellung nicht gefunden.</p>}
      {isLoading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {order && !order.error && <div>
        <p><b>Status:</b> {statusMap[order.status]}</p>
        <p><b>Zahlmethode:</b> {order.payment_method.display_name}</p>
        <p><b>Versandmethode:</b> {order.shipping_method.display_name}</p>
        {order.tracking_number && <p><b>Sendungsverfolgungsnummer:</b> {order.tracking_number}</p>}

        {/* Paypal */}
        {order.payment_method.id === 4 && paypal && order.status === 'open' && !paymentApproved && <div>
          <p>Jetzt mit Paypal bezahlen und Bestellung abschließen.</p>
          {payPalOrder && <PayPal action='PAY_NOW' order={payPalOrder} setApproved={onApprove}/>}
          <p style={{ fontSize: '0.8rem' }}>Mit klick auf den PayPal Button werden Sie auf die PayPal Seite
            weitergeleitet um Ihre Bestellung abzuschließen.</p>
        </div>}

        {/* Vorkasse */}
        {order.payment_method.id === 2 && order.status === 'open' && <div>
          <p>Bitte überweisen Sie mit Angabe der Bestellnummer ({order.uuid}) <b><PriceTag
            price={currency(order.total_brutto).add(shippingCost).value}
            replaceZero={false} showInfo={false}/></b> an:</p>
          <p><b>{contact.company_name}</b></p>
          <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: settingsData.bank_details }}/>
          <p>Sobald Ihre Zahlung bei uns eingegangen ist werden wir Ihre Artikel verschicken, bzw. zur Abholung
            vorbereiten.</p>
          <p>Weitere Informationen erhalten Sie per E-Mail.</p>
        </div>}

        {/* Klarna */}
        {order.payment_method.id === 5 && order.status === 'open' && !paymentApproved && <div>
          <Spacer />
          <p>Jetzt mit Klarna bezahlen und Bestellung abschließen.</p>
          <Klarna order={order} onApprove={onApprove} />
          <Spacer />
        </div>}
        <Flex flexWrap='wrap'>
          {order.shipping_method.id !== 1 && <Box width={['100%', '50%', '50%', '50%']}>
            <h3>Lieferadresse</h3>
            <p>
              {addresses.shipping.name} {addresses.shipping.surname} <br/>
              {addresses.shipping.company && <>{addresses.shipping.company}<br/></>}
              {addresses.shipping.street}<br/>
              {addresses.shipping.addition && <>{addresses.shipping.addition}<br/></>}
              {addresses.shipping.zip},&nbsp;
              {addresses.shipping.city}<br/>
              {addresses.shipping.phone}
            </p>
          </Box>}
          <Box width={['100%', '50%', '50%', '50%']}>
            <h3>Rechnungsadresse</h3>
            <p>
              {addresses.invoice.name} {addresses.invoice.surname} <br/>
              {addresses.invoice.company && <>{addresses.invoice.company}<br/></>}
              {addresses.invoice.street}<br/>
              {addresses.invoice.addition && <>{addresses.invoice.addition}<br/></>}
              {addresses.invoice.zip},&nbsp;
              {addresses.invoice.city}<br/>
              {addresses.invoice.phone}
            </p>
          </Box>
        </Flex>
        <Spacer/>
        <h3>Bestellübersicht</h3>
        <Cart orderConfirmationMode={true} orderCart={{
          products: order.products,
          totalCount: order.products.length,
          totalPriceBrutto: order.total_brutto
        }} orderShippingCost={shippingCost}/>
      </div>}
    </Wrapper>
  )
};

export default OrderConfirmationPage;