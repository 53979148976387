import React from 'react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import Cart from '../components/Cart/Cart';

const CartPage = () => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading>Warenkorb</Heading>
      <Spacer/>
      <Cart/>
      <Spacer/>
    </Wrapper>
  )
};

export default CartPage;