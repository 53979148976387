import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Select,
  CloseButton,
  useToast,
  Flex,
  Box,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';

import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';

import CountSelector       from '../CountSelector/CountSelector';
import { StoreContext }    from '../../context/Store';
import useIsInCart         from '../../hooks/useIsInCart';
import PersonalizationInfo from '../Personalization/PersonalizationInfo';

const disabledMessages = {
  outOfStock: 'Produkt aktuell nicht verfügbar.',
  maximumInCart: 'Maximale Anzahl bereits im Warenkorb.'
}

const AddToCartButton = () => {
  const toast = useToast();
  const {
    cartDispatcher,
    currentProduct,
    currentProductDispatcher,
    settings,
    storeRootPath
  } = useContext(StoreContext);
  const [disabledMsgKey, setDisabledMsgKey] = useState('maximumInCart');
  const [canAddToCart, setCanAddToCart] = useState(true);

  const { isInCart, count, loading } = useIsInCart(currentProduct.details, currentProduct.variantSelected);

  useEffect(() => {
    if (currentProduct && !loading) {
      if (currentProduct.stock === 0) {
        setDisabledMsgKey('outOfStock');
        setCanAddToCart(false);
      } else {
        if (isInCart) {
          if (count === currentProduct.stock) {
            setCanAddToCart(false);
          } else {
            setCanAddToCart(true);
          }
        } else {
          setCanAddToCart(true);
        }
      }
    }

    //eslint-disable-next-line
  }, [currentProduct, isInCart, loading]);

  const onClickAddToCart = () => {
    cartDispatcher({
      type: 'add',
      payload: {
        product: currentProduct.details,
        priceBrutto: currentProduct.priceBrutto,
        count: currentProduct.countSelected,
        variant: currentProduct.variantSelected,
        path: `${storeRootPath}/${currentProduct.details.category.slug}/${currentProduct.details.slug}/${currentProduct.details.id}`,
        personalization: currentProduct.personalization,
      }
    });
    toast({
      title: currentProduct.details.display_name,
      description: `${currentProduct.countSelected}x ${currentProduct.variantSelected ? `in ${currentProduct.variantDisplayName} ${currentProduct.variantSelected.display_name}` : ''} in den Warenkorb gelegt.`,
      status: 'success',
      isClosable: true
    })
  };

  const onChangeVariant = (variant) => {
    currentProductDispatcher({
      type: 'variantChange',
      payload: variant
    })
  }

  const onChangeCount = (newCount) => {
    currentProductDispatcher({
      type: 'countChange',
      payload: parseInt(newCount)
    })
  }

  const onPersonalizationRemove = () => {
    currentProductDispatcher({
      type: 'personalizationChange',
      payload: null
    })
    toast({
      title: 'Personalisierung entfernt ',
      status: 'info',
      isClosable: true,
    })
  }

  return (
    <>
      {currentProduct.hasVariants && <>
        <FormLabel htmlFor="Variante">
          {currentProduct.variantDisplayName}
        </FormLabel>
        {/*eslint-disable-next-line*/}
        <Select id="variante" value={JSON.stringify(currentProduct.variantSelected)}
                onChange={e => onChangeVariant(JSON.parse(e.target.value))} maxWidth={'50%'}>
          <option value={'{}'}>{currentProduct.variantDisplayName} wählen</option>
          {currentProduct.variantsAvailable.map(variant => {
            return <option key={variant.id} value={JSON.stringify(variant)}
                           disabled={!variant.active}>{variant.display_name}</option>
          })}
        </Select>
      </>}
      <Spacer height={10} />
      {currentProduct.personalization && <Flex alignItems='center' mb={5}><b>Personalisierung hinzugefügt</b> <Box ml={2}><PersonalizationInfo personalization={currentProduct.personalization} /></Box>
        <Tooltip label='Personalisierung entfernen'>
          <CloseButton display="inline-block" ml={2} size="md" aria-label="Personalisierung entfernen"
                       onClick={onPersonalizationRemove}/>
        </Tooltip>
      </Flex>}
      <Flex flexWrap='wrap'>
        {currentProduct.stock > 1 && <CountSelector isInCart={isInCart} countInCart={count} currentCount={currentProduct.currentCount}
                        productStock={currentProduct.stock} onChangeCount={onChangeCount} disabled={!canAddToCart}/>}
        <Button colorScheme="brand"
                mt={5}
                onClick={() => onClickAddToCart()}
                isDisabled={!canAddToCart || (!settings.data.purchase_to_order && !currentProduct.reorderPossible) || currentProduct.priceBrutto === 0 || (currentProduct.hasVariants && !currentProduct.variantSelected)}>
          Zum Warenkorb hinzufügen</Button>
      </Flex>
      {!canAddToCart && <p>{disabledMessages[disabledMsgKey]}</p>}
    </>
  )
};

export default AddToCartButton;