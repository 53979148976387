import React            from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@chakra-ui/react'

const getText = (p) => {
  return Object.keys(p).map(v => `${v}: ${p[v]} \n`).join('')
}

const PersonalizationInfo = ({ personalization }) => {
  const text = getText(personalization.values);
  return (
    <Tooltip label={text}>
      <span>
        <FaInfoCircle style={{verticalAlign: 'middle'}}/>
      </span>
    </Tooltip>
  )
};

export default PersonalizationInfo;