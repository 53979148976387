import React from 'react';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Link    from '@interness/web-core/src/components/elements/Link/Link';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

const Error = () => {
  return (
    <Wrapper>
      <Spacer />
      <Heading>Fehler</Heading>
      <p>Bitte versuchen Sie es später erneut.</p>
      <Link to={'/'}>Zurück nach Hause</Link>
    </Wrapper>
  )
};

export default Error;