import React, { useContext } from 'react';
import { Link }              from 'gatsby';
import { FaTimesCircle }     from 'react-icons/fa';
import currency              from 'currency.js';
import { Button }            from '@chakra-ui/react';

import Spacer             from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator          from '@interness/web-core/src/components/structure/Separator/Separator';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';

import { StoreContext } from '../../context/Store';
import CountSelector    from '../CountSelector/CountSelector';

import * as S              from './styles';
import { endpoints }       from '../../config';
import PriceTag            from '../PriceTag';
import { CheckoutContext } from '../../context/Checkout';

const Cart = ({ checkoutMode, orderCart, orderConfirmationMode, orderShippingCost }) => {
  if (orderConfirmationMode) {
    checkoutMode = true;
  }
  const { cart: localCart, cartDispatcher, storeRootPath, settings } = useContext(StoreContext);
  const { shippingCost: checkoutShippingCost } = useContext(CheckoutContext);
  let cart;
  const shippingCost = orderShippingCost ? orderShippingCost : checkoutShippingCost;
  if (orderCart) {
    cart = {
      ...orderCart,
      products: orderCart.products.map(p => {
        const struc = {
          ...p,
          product: p.product_id
        };
        delete struc.product_id;
        return struc;
      })
    }
  } else {
    cart = localCart
  }
  const totalWithShipping = currency(cart.totalPriceBrutto).add(shippingCost).value;

  // const features = [''];

  const onRemoveProduct = (id) => {
    try {
      cartDispatcher({ type: 'remove', payload: { id } });
    } catch (e) {
      console.log(e)
    }
  }

  const onChangeCount = (newCount, cartItemId) => {
    cartDispatcher({ type: 'changeCount', payload: { cartId: cartItemId, newCount: parseInt(newCount) } })
  };

  return (
    <>
      {cart.totalCount === 0 && <>
        <p>Der Warenkorb ist leer.</p>
        <Link to={storeRootPath}>Jetzt Produkte entdecken.</Link>
        <br/>
        <Spacer/>
      </>}
      {cart.totalCount > 0 && <S.List>
        {cart.products.map(cartItem => {
          const { product } = cartItem;
          const stock = cartItem.variant ? cartItem.variant.stock : product.stock;
          return (
            <S.Row key={cartItem.cartId || cartItem.product.id}>
              {checkoutMode && <div>
                {cartItem.count}x
              </div>}
              <div>
                <ConditionalWrapper wrapper={children => <Link to={cartItem.path}>{children}</Link>}
                                    condition={cartItem.path}>
                  <div>
                    {!checkoutMode && <>
                      {product.images.length === 0
                        ? <img src="https://placehold.jp/333/ffffff/100x100.png?text=Kein%20Bild" alt="Kein Bild"/>
                        : <img
                          src={`${endpoints.thumbnails}/${product.images.filter(image => !!image.file_id)[0].file_id.private_hash}?key=e-detail-thumbnail`}
                          alt={product.display_name}/>
                      }
                    </>}
                    <span>{product.display_name}</span>
                  </div>
                </ConditionalWrapper>
                {cartItem.personalization && <p>Personalisiert</p>}
                {cartItem.variant && <>
                  <b>Variante:</b>
                  <span style={{ marginLeft: '5px' }}>{cartItem.variant.display_name}</span>
                </>}
              </div>
              {!checkoutMode && <div>
                <p>Einzelpreis</p>
                <PriceTag price={cartItem.priceBrutto}/>
              </div>}
              {!checkoutMode && <div>
                <p>Zwischensumme</p>
                <PriceTag price={currency(cartItem.priceBrutto).multiply(cartItem.count)}/>
              </div>}
              {!checkoutMode && <div style={{ display: 'flex' }}>
                <CountSelector cartMode
                               isInCart={cartItem}
                               countInCart={cartItem.count}
                               currentCount={cartItem.count}
                               productStock={stock}
                               onChangeCount={onChangeCount}/>
                <S.RemoveButton aria-label="Aus Warenkorb entfernen" onClick={() => onRemoveProduct(cartItem.cartId)}>
                  <FaTimesCircle/>
                </S.RemoveButton>
              </div>}
            </S.Row>
          );
        })}
      </S.List>}
      <Separator/>
      {cart.totalCount > 0 && <div style={{ textAlign: 'right' }}>
        <>
          <p style={{ fontSize: '0.6rem', marginBottom: '0', fontWeight: 'bold' }}>Zwischensumme (exkl.
            Versandkosten)</p>
          <b style={{ fontSize: '1.4rem' }}><PriceTag showInfo={false}
                                                      price={cart.totalPriceBrutto}/></b>
        </>
        <Spacer height={20}/>
        <>
          <div style={{ fontSize: '0.8rem' }}>Versandkosten: <PriceTag replaceZero={false}
                                                                       price={shippingCost}/>
          </div>
          <Spacer height={20}/>
        </>
        <>
          <p style={{ fontSize: '0.6rem', marginBottom: '0', fontWeight: 'bold' }}>Gesamtsumme</p>
          <b style={{ fontSize: '1.4rem' }}><PriceTag showInfo={false} price={totalWithShipping}/></b>
        </>
        {settings && settings.data.default_tax_percent > 0 &&
        <div style={{ fontSize: '0.6rem' }}>Enthaltene Mehrwertsteuer: <PriceTag showMwst replaceZero={false}
                                                                                 price={totalWithShipping}/>
        </div>}

        <Spacer height={40}/>
        <div style={{ textAlign: 'center', maxWidth: '640px', margin: 'auto' }}>
          {!checkoutMode && <div>
            <div>
              <Button width="100%" colorScheme="brand" as={Link} to={`${storeRootPath}/kasse`}
                      isDisabled={cart.totalCount === 0}>
                Zur Kasse gehen
              </Button>
              <Spacer height={10}/>
            </div>
            {cart.totalCount !== 0 &&
            <div style={{ marginTop: '20px' }}>
              <Button width="100%" colorScheme="brand" variant="outline" as={Link} to={storeRootPath}>Weiter
                einkaufen</Button>
            </div>
            }
          </div>}
        </div>
      </div>}
    </>
  )
};

Cart.defaultProps = {
  shippingCost: 0,
}

export default Cart;