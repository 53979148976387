import React, { useState, useContext } from 'react';
import cogoToast                       from 'cogo-toast';
import { navigate }                    from 'gatsby';
import { queryCache }                  from 'react-query';
import { Button, Checkbox }            from '@chakra-ui/react';

import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

import { CheckoutContext } from '../../context/Checkout';
import { StoreContext }    from '../../context/Store';
import { EApiContext }     from '../../context/EApi';

const CheckoutCheck = () => {
  const [agbChecked, setAgbChecked] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const { cart, cartDispatcher, storeRootPath, projectName } = useContext(StoreContext);
  const { createOrder } = useContext(EApiContext);
  const {
    customerEmail,
    shippingAddress,
    invoiceAddress,
    comment,
    paymentMethod,
    shippingMethod,
    shippingCost,
    presentWrap,
    resetAll
  } = useContext(CheckoutContext);

  const order = {
    project: projectName,
    orderPath: document.location.host + storeRootPath + '/bestellung/',
    customer: {
      email: customerEmail,
      addresses: [
        shippingAddress,
        invoiceAddress
      ]
    },
    cart,
    comment,
    presentWrap,
    paymentMethod,
    shippingMethod,
    shippingCost,
  };

  const onOrder = async () => {
    if (customerEmail && shippingAddress && invoiceAddress && paymentMethod && shippingMethod) {
      setOrderLoading(true);

      const res = await createOrder(order);

      if (res.status !== 'ok') {
        cogoToast.error('Bestellung fehlgeschlagen. Bitte versuchen Sie es später erneut', {
          heading: 'Fehler',
          position: 'bottom-center',
          hideAfter: 5,
        });
        setOrderLoading(false);
      } else {
        await navigate(`${storeRootPath}/bestellung/${res.orderSummary.uuid}`, { state: { fromCheckout: true } });
        cogoToast.success('Bestellung aufgegeben.', {
          heading: 'Bestellung aufgegeben.',
          position: 'bottom-center',
          hideAfter: 5,
        });
        await cleanupAfterOrder()
        setOrderLoading(false);
      }
      setOrderLoading(false);
    } else {
      cogoToast.error('Bitte füllen Sie alle Pflichtfelder aus.', {
        heading: 'Fehler',
        position: 'bottom-center',
        hideAfter: 5,
      });
    }
  }

  const cleanupAfterOrder = async () => {
    await queryCache.invalidateQueries('products');
    await queryCache.invalidateQueries('product');
    await cartDispatcher({ type: 'reset' });
    await resetAll()
  }

  return (
    <div style={{ maxWidth: '90%', margin: 'auto' }}>
      <div style={{ textAlign: 'center' }}>
        <Checkbox type="checkbox" id="agb-check" name="agb-check" checked={agbChecked}
                  colorScheme="brand"
                  onChange={() => setAgbChecked(!agbChecked)} style={{ marginRight: '10px' }}>
          Ich erkläre mich mit den <Link to="/agb">AGB</Link>, der <Link
          to="/widerruf">Widerrufsbelehrung</Link> und den <Link
          to="/versand-und-zahlung">Versand- und Zahlungsbedingungen</Link> einverstanden.
        </Checkbox>
        <Spacer height={20}/>
        {!orderLoading &&
        <Button colorScheme="brand" variant="solid" width="100%" isDisabled={!agbChecked || orderLoading}
                onClick={onOrder}>Kostenpflichtig
          bestellen</Button>}
        {orderLoading && <SpinnerContainer><Spinner/></SpinnerContainer>}
        {paymentMethod && paymentMethod.id === 4 &&
        <p>Im Anschluss werden Sie zur Zahlung mit PayPal aufgefordert um Ihre Bestellung abzuschließen.</p>}
      </div>
    </div>
  )
}

export default CheckoutCheck;