import React, { useContext }                                     from 'react';
import { useFormState }                                          from 'react-use-form-state';
import cogoToast                                                 from 'cogo-toast';
import { Button, FormLabel, Heading, Input, Textarea, Checkbox } from '@chakra-ui/react';

import { CheckoutContext } from '../../context/Checkout';
import Address             from '../Address/Address';
import { Form }            from '../Address/styles';

const CheckoutInformation = ({ nextStep, shippingCountries, presentWrapping }) => {
  const { customerEmail, shippingAddress, setCustomerEmail, setComment, setPresentWrap } = useContext(CheckoutContext);

  const initialState = () => {
    if (customerEmail) {
      return {
        email: customerEmail,
        country: shippingCountries[0].country_id.id
      }
    } else {
      return {
        country: shippingCountries[0].country_id.id
      }
    }
  }

  const [formState, { email, label, checkbox, textarea }] = useFormState(initialState(), {
    withIds: true
  });

  const isDataValid = () => {
    return customerEmail && shippingAddress.surname && shippingAddress.name && shippingAddress.street && shippingAddress.zip && shippingAddress.city;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isDataValid()) {
      const { comment } = formState.values;
      await setComment(comment)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      nextStep();
    } else {
      cogoToast.error('Bitte füllen Sie alle mit * gekennzeichneten Felder aus.', {
        heading: 'Pflichtfelder ausfüllen',
        position: 'bottom-center',
        hideAfter: 5,
      })
    }
  }

  return (
    <div>
      <Heading as="h2"
               style={{ marginLeft: '30px', marginRight: '30px', marginTop: '20px' }}>Kontaktinformation</Heading>
      <Form onSubmit={e => e.preventDefault()}>
        <FormLabel {...label('email')}>
          E-Mail*
          <Input className={formState.validity.email ? 'valid' : null} {...email({
            name: 'email',
            onBlur: () => setCustomerEmail(formState.values.email)
          })} required/>
        </FormLabel>
      </Form>

      <Heading as="h2" style={{ marginLeft: '30px', marginRight: '30px' }}>Lieferadresse</Heading>
      <Address addressType="shipping" shippingCountries={shippingCountries}/>

      <Heading as="h2" style={{ marginLeft: '30px', marginRight: '30px' }}>Weitere</Heading>
      <Form onSubmit={e => e.preventDefault()}>
        <div>
          <FormLabel {...label('comment')}>
            Kommentar
            <Textarea {...textarea('comment')} rows={3}/>
          </FormLabel>
        </div>
        {presentWrapping && <div>
          <Checkbox colorScheme="brand" {...checkbox({
            name: 'present_wrap',
            onBlur: () => setPresentWrap(formState.values.present_wrap)
          })}>Bestellung als Geschenk verpacken</Checkbox>
        </div>}
      </Form>
      <div style={{ maxWidth: '90%', margin: 'auto' }}>
        <Button colorScheme="brand" variant="solid" width="100%" onClick={(e) => onSubmit(e)}>Weiter</Button>
      </div>
    </div>
  )
}

export default CheckoutInformation;