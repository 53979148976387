import React, { useContext, useState, useEffect } from 'react';
import {
  Heading,
  Button,
  FormLabel,
  Select,
  Textarea,
  Box,
  useToast,
  Tooltip,
}                                                 from '@chakra-ui/react';

import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';

import { StoreContext } from '../../context/Store';

const SelectField = ({ field, onChangeFieldValue }) => {
  const { display_name, description, options } = field;

  useEffect(() => {
    onChangeFieldValue(display_name, options[0]);
  }, [])

  return (
    <Box my={5}>
      <FormLabel htmlFor={display_name}><b>{display_name}</b></FormLabel>
      <Select variant="filled" id={display_name} onChange={e => onChangeFieldValue(display_name, e.target.value)}
              required>
        {options.map(option => <option key={option} value={option}>{option}</option>)}
      </Select>
      {description && <p>{description}</p>}
    </Box>
  )
}

const TextareaField = ({ field, onChangeFieldValue }) => {
  const [charCount, setCharCount] = useState(0);
  const { display_name, description, max_input_length } = field;

  const CharCount = <p>({`${charCount} / ${max_input_length}`})</p>

  const onChange = (value) => {
    setCharCount(value.length);
    onChangeFieldValue(display_name, value);
  }

  return (
    <Box my={5}>
      <FormLabel htmlFor={display_name}><b>{display_name} {max_input_length && CharCount}</b></FormLabel>
      <Textarea variant="filled" id={display_name} maxLength={max_input_length && max_input_length}
                onBlur={e => onChange(e.target.value)}
                onChange={e => onChange(e.target.value)} required/>
      {description && <p>{description}</p>}
    </Box>
  )
}

const Personalization = ({ disabled }) => {
  const toast = useToast();
  const { currentProduct, currentProductDispatcher } = useContext(StoreContext);
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = useState();

  const personalization = currentProduct.details.personalization;

  const checkValidity = (setFields) => {
    let valid = false;
    personalization.fields.forEach(field => {
      valid = !!(setFields[field.display_name] && setFields[field.display_name].length >= 1);
    })
    setIsValid(valid);
  }

  const onChangeFieldValue = (field, value) => {
    const localState = { ...state };
    localState[field] = value;
    setState(localState);

    checkValidity(localState)
  }

  const onSavePersonalization = () => {
    currentProductDispatcher({
      type: 'personalizationChange',
      payload: {
        config: personalization,
        values: state,
      }
    })
    toast({
      title: 'Personalisierung erfolgreich hinzugefügt',
      status: 'success',
      isClosable: true
    })
  }

  return (
    <div>
      <Heading as="h3" size="lg">{personalization.display_name}</Heading>
      <div>
        {personalization.fields.map((field, i) => {
          switch (field.type) {
            case 'sel':
              return <SelectField key={field.display_name + i} field={field} onChangeFieldValue={onChangeFieldValue}/>;
            case 'textar':
              return <TextareaField key={field.display_name + i} field={field}
                                    onChangeFieldValue={onChangeFieldValue}/>;
            default:
              return <p key={field.display_name + i}>Field not found</p>
          }
        })}
      </div>
      <Box textAlign="right">
        <Button colorScheme="brand" isDisabled={disabled || !isValid} onClick={onSavePersonalization}>Speichern</Button>
        {currentProduct.hasVariants && !currentProduct.variantSelected && <p>Bitte Variante wählen</p>}
      </Box>
    </div>
  )
};

export default Personalization;