import React, { useContext }                   from 'react';
import { useFormState }                        from 'react-use-form-state';
import { FormLabel, Input, Select, Flex, Box } from '@chakra-ui/react';

import { CheckoutContext } from '../../context/Checkout';
import { Form }            from './styles';

const Address = ({ addressType, shippingCountries }) => {
  const { shippingAddress, setShippingAddress, invoiceAddress, setInvoiceAddress } = useContext(CheckoutContext);

  const initialState = () => {
    if (addressType === 'shipping' && shippingAddress) {
      const sa = {
        ...shippingAddress
      }
      delete sa.address_type;
      return sa;
    } else if (addressType === 'invoice' && invoiceAddress) {
      const ia = {
        ...invoiceAddress
      }
      delete ia.address_type;
      return ia;
    } else {
      return { country: shippingCountries[0].country_id.id }
    }
  };

  const [formState, { text, label, number, select }] = useFormState(initialState(), {
    withIds: true
  });

  const onSave = async () => {
    const { surname, name, company, street, addition, zip, city, country, phone } = formState.values;

    const addressStructure = {
      address_type: addressType,
      surname,
      name,
      company,
      street,
      addition,
      zip,
      city,
      country,
      phone,
    }

    if (addressType === 'shipping') {
      setShippingAddress(addressStructure);
    } else if (addressType === 'invoice') {
      setInvoiceAddress(addressStructure);
    }
  }

  const onBlur = (e) => {
    e.preventDefault();
    onSave();
  }

  return (
    <Form>
      <div>
        <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems="center">
          <Box width={['100%', '48%', '48%', '48%']}>
            <FormLabel {...label('name')}>
              Vorname*
              <Input className={formState.validity.name ? 'valid' : null} {...text({
                name: 'name',
                onBlur: e => onBlur(e)
              })} required/>
            </FormLabel>
          </Box>
          <Box width={['100%', '48%', '48%', '48%']}>
            <FormLabel {...label('surname')}>
              Nachname*
              <Input className={formState.validity.surname ? 'valid' : null} {...text({
                name: 'surname',
                onBlur: e => onBlur(e)
              })} required/>
            </FormLabel>
          </Box>
        </Flex>
      </div>
      <div>
        <FormLabel {...label('company')}>
          Firma
          <Input className={formState.validity.company ? 'valid' : null} {...text({
            name: 'company',
            onBlur: e => onBlur(e)
          })} />
        </FormLabel>
      </div>
      <div>
        <FormLabel {...label('street')}>
          Straße und Hausnummer*
          <Input className={formState.validity.street ? 'valid' : null} {...text({
            name: 'street',
            onBlur: e => onBlur(e)
          })} required/>
        </FormLabel>
      </div>
      <div>
        <FormLabel {...label('addition')}>
          Adresszusatz
          <Input className={formState.validity.addition ? 'valid' : null} {...text({
            name: 'addition',
            onBlur: e => onBlur(e)
          })} />
        </FormLabel>
      </div>
      <div>
        <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems="center">
          <Box width={['100%', '48%', '48%', '48%']}>
            <FormLabel {...label('zip')}>
              Postleitzahl*
              <Input className={formState.validity.zip ? 'valid' : null} {...text({
                name: 'zip',
                onBlur: e => onBlur(e)
              })} required/>
            </FormLabel>
          </Box>
          <Box width={['100%', '48%', '48%', '48%']}>
            <FormLabel {...label('city')}>
              Stadt*
              <Input className={formState.validity.city ? 'valid' : null} {...text({
                name: 'city',
                onBlur: e => onBlur(e)
              })} required/>
            </FormLabel>
          </Box>
        </Flex>
      </div>
      <div>
        <FormLabel {...label('country')}>
          Land*
          <Select className={formState.validity.country ? 'valid' : null} {...select({
            name: 'country',
            onBlur: e => onBlur(e)
          })} required>
            {shippingCountries.map(country => <option
              value={country.country_id.id} key={country.country_id.id}>{country.country_id.display_name}</option>)
            }
          </Select>
        </FormLabel>
      </div>
      <div>
        <FormLabel {...label('phone')}>
          Telefonnummer
          <Input className={formState.validity.email ? 'valid' : null} {...number({
            name: 'phone',
            onBlur: e => onBlur(e)
          })}/>
        </FormLabel>
      </div>
    </Form>
  )
}

export default Address;