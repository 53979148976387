import React, { useContext, useState, useEffect } from 'react';
import { Button, Radio, RadioGroup }              from '@chakra-ui/react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import { CheckoutContext } from '../../context/Checkout';
import PriceTag            from '../PriceTag';

const CheckoutShipping = ({ methods, defaultShippingCost, nextStep, previousStep }) => {
  const {
    shippingMethod,
    setShippingMethod,
    setShippingCost,
    setPaymentMethod,
    availableShippingMethods
  } = useContext(CheckoutContext);
  const [shipping, setShipping] = useState(null);

  const onSubmit = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (!shippingMethod) {
      setShippingMethod(shipping)
    }
    if (shippingMethod && !shippingMethod.free) {
      setShippingCost(defaultShippingCost);
    } else {
      setShippingCost(0);
    }
    nextStep();
  };

  const onChange = (value) => {
    console.log(value)
    setShipping(JSON.parse(value));
    setShippingMethod(JSON.parse(value));
    setPaymentMethod(null)
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!shipping) {
        setShippingCost(defaultShippingCost)
        setShipping(methods[0].shipping_id)
        // setShippingMethod(methods[0])
      }
    }

    return () => {
      mounted = false;
    }
    //eslint-disable-next-line
  }, [shipping, shippingMethod])

  return (
    <div style={{ maxWidth: '90%', margin: 'auto' }}>
      <RadioGroup name="shipping_method" value={JSON.stringify(shipping)} defaultValue={JSON.stringify(shipping)} onChange={onChange}>
        {methods.map(method => {
          return (
            <div key={method.shipping_id.id}
                 style={{ padding: '10px', border: '1px solid lightgray', marginBottom: '10px', borderRadius: '3px' }}>
              <Radio id={method.shipping_id.display_name}
                     colorScheme="brand"
                    value={JSON.stringify(method.shipping_id)}>
              {method.shipping_id.display_name}
              {!method.shipping_id.free && <> - <PriceTag priceNetto={defaultShippingCost}
                                                          showInfo={false}/></>}
              </Radio>
            </div>
          );
        })}
      </RadioGroup>
      <Spacer/>
      <div style={{ margin: 'auto' }}>
        <Button colorScheme="brand" variant="solid" width="100%" onClick={onSubmit}>Weiter</Button>
        <Spacer height={20}/>
        <Button colorScheme="brand" variant="outline" width="100%" onClick={previousStep}>Zurück</Button>
      </div>
    </div>
  )
}

export default CheckoutShipping;