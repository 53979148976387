import React, { useContext }             from 'react';
import styled                            from '@emotion/styled';
import { AiFillCheckCircle, AiFillEdit } from 'react-icons/ai';
import { Flex, Box }                     from '@chakra-ui/react';
import { Heading }                       from '@chakra-ui/react';
import { readableColor }                 from 'polished';

import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator from '@interness/web-core/src/components/structure/Separator/Separator';

import { CheckoutContext } from '../../context/Checkout';

const List = styled.ul`
  list-style-type: none;
  margin: auto;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  li {
    margin: 0;

    &.active {
      border-bottom: 2px solid ${props => props.theme.brand_color};
    }

    button {
      background: unset;
      border: none;
      cursor: pointer;
      color: ${props => readableColor(props.theme.base_color)};

      svg {
        vertical-align: text-top;
        margin-right: 10px;
        fill: ${props => props.theme.brand_color};
      }

      &:disabled {
        cursor: default;
      }
    }
  }
`;

const EditButton = styled.button`
  border: none;
  background: unset;
  margin-right: 5px;
  cursor: pointer;
  color: ${props => readableColor(props.theme.base_color)};

  svg {
    vertical-align: text-top;
  }
`;

const CheckoutNav = (props) => {
  const {
    customerEmail,
    shippingAddress,
    invoiceAddress,
    shippingMethod,
    paymentMethod,
    comment
  } = useContext(CheckoutContext);

  const { currentStep, goToStep } = props;

  const steps = [
    {
      name: 'Informationen',
      validated: !!customerEmail && !!shippingAddress && shippingAddress.surname && shippingAddress.name && shippingAddress.street && shippingAddress.zip && shippingAddress.city,
      allowed: true,
    },
    {
      name: 'Versand',
      validated: !!shippingMethod,
      allowed: !!customerEmail && !!shippingAddress && shippingAddress.surname && shippingAddress.name && shippingAddress.street && shippingAddress.zip && shippingAddress.city,
    },
    {
      name: 'Zahlung',
      validated: !!paymentMethod,
      allowed: !!shippingMethod,
    },
    {
      name: 'Überprüfen',
      validated: false,
      allowed: !!paymentMethod,
    },
  ]

  const onEditClick = (step) => {
    goToStep(step);
  };

  return (
    <>
      <nav>
        <List>
          {steps.map((step, i) => {
            const current = currentStep === i + 1;
            return <li className={current ? 'active' : ''} key={step.name}>
              <button onClick={() => goToStep(i + 1)} disabled={!step.allowed}>
                {current && <AiFillEdit/>}
                {step.validated && !current && <AiFillCheckCircle/>}
                {step.name}
              </button>
            </li>;
          })}
        </List>
      </nav>
      {currentStep !== 1 &&
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
          <>{currentStep === 4 && <Heading as="h2" style={{ marginTop: '20px' }}>Überprüfen</Heading>}</>
          <Spacer/>
          {!!customerEmail && <div><EditButton onClick={() => onEditClick(1)}
                                               aria-label="Kontaktdaten bearbeiten"><AiFillEdit/></EditButton><b>Kontakt:</b> {customerEmail}
          </div>}
          <Flex flexWrap="wrap">
            {!!shippingAddress && shippingMethod && shippingMethod.id !== 1 &&
              <Box width={['100%', '50%', '50%', '50%']}>
                <div>
                  <Spacer height={20}/>
                  <EditButton onClick={() => onEditClick(1)}
                              aria-label="Lieferadresse bearbeiten"><AiFillEdit/></EditButton>
                  <b>Lieferadresse:</b>
                  <p>
                    {shippingAddress.name} {shippingAddress.surname} <br/>
                    {shippingAddress.company && <>{shippingAddress.company}<br/></>}
                    {shippingAddress.street}<br/>
                    {shippingAddress.addition && <>{shippingAddress.addition}<br/></>}
                    {shippingAddress.zip},&nbsp;
                    {shippingAddress.city}<br/>
                    {shippingAddress.phone}
                  </p>
                </div>
              </Box>}
            <Box width={['100%', '50%', '50%', '50%']}>
              {!!invoiceAddress && <div>
                <Spacer height={20}/>
                <EditButton onClick={() => onEditClick(3)}
                            aria-label="Rechnungsadresse bearbeiten"><AiFillEdit/></EditButton>
                <b>Rechnungsadresse:</b>
                <p>
                  {invoiceAddress.name} {invoiceAddress.surname} <br/>
                  {invoiceAddress.company && <>{invoiceAddress.company}<br/></>}
                  {invoiceAddress.street}<br/>
                  {invoiceAddress.addition && <>{invoiceAddress.addition}<br/></>}
                  {invoiceAddress.zip},&nbsp;
                  {invoiceAddress.city}<br/>
                  {invoiceAddress.phone}
                </p>
              </div>}
            </Box>
          </Flex>
          {!!shippingMethod && <div>
            <EditButton onClick={() => onEditClick(2)}
                        aria-label="Versandmethode bearbeiten"><AiFillEdit/></EditButton>
            <b>Versand:</b> {shippingMethod.display_name}
          </div>}
          {!!paymentMethod && <div>
            <EditButton onClick={() => onEditClick(3)}
                        aria-label="Zahlmethode bearbeiten"><AiFillEdit/></EditButton>
            <b>Zahlung:</b> {paymentMethod.display_name}
          </div>}
          {comment && <div>
            <EditButton onClick={() => onEditClick(1)}
                        aria-label="Kommentar bearbeiten"><AiFillEdit/></EditButton>
            <b>Kommentar:</b> {comment}
          </div>}
          <Separator spacing={20}/>
        </div>
      }
    </>
  )
}

export default CheckoutNav;