import React, { useContext, useEffect, useState } from 'react';
import SVG                                        from 'react-inlinesvg';
import { Button, Radio, RadioGroup }              from '@chakra-ui/react';

import useScript from '@interness/web-core/src/hooks/useScript';
import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';

import { EApiContext } from '../../context/EApi';
import Error           from '../../templates/Error';

const Klarna = ({ order, onApprove }) => {
  const [loaded] = useScript('https://x.klarnacdn.net/kp/lib/v1/api.js');
  const [loading, setLoading] = useState(false);
  const [klarnaLoaded, setKlarnaLoaded] = useState(false);
  const [klarnaStatus, setKlarnaStatus] = useState(null);
  const [paymentMethodCategory, setPaymentMethodCategory] = useState(order.klarna.payment_method_categories[0].identifier);
  const { placeKlarnaOrder } = useContext(EApiContext)

  const initKlarna = async () => {
    await window.Klarna.Payments.init({
      client_token: order.klarna.client_token
    })
  }

  const loadKlarnaPaymentMethod = async (method) => {
    setPaymentMethodCategory(method);
    await window.Klarna.Payments.load({
      container: '#klarna-payments-container',
      payment_method_category: method,
    }, res => {
      setKlarnaStatus(res)
    })
  }

  const onClickPay = async () => {
    setLoading(true);
    await window.Klarna.Payments.authorize({
      payment_method_category: paymentMethodCategory,
      auto_finalize: true
    }, async res => {
      if (res.approved && res.authorization_token) {
        const klarnaOrder = await placeKlarnaOrder(res.authorization_token, order.uuid);
        console.log(klarnaOrder)
        if (klarnaOrder.message === 'ok') {
          setLoading(false);
          onApprove(true)
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    if (loaded && order && !klarnaLoaded) {
      initKlarna();
      loadKlarnaPaymentMethod(paymentMethodCategory)
      setKlarnaLoaded(true);
    }
  }, [loaded, order, klarnaLoaded])

  return (
    <div>
      <RadioGroup value={paymentMethodCategory}>
        {order.klarna.payment_method_categories.map(category => <Radio key={category.identifier}
                                                                       value={category.identifier}
                                                                       colorScheme="brand"
                                                                       onChange={e => loadKlarnaPaymentMethod(e.target.value)}
        >{category.name} <SVG
          src={category.asset_urls.standard}/></Radio>)}
      </RadioGroup>
      <div id="klarna-payments-container"/>
      {klarnaStatus && klarnaStatus.show_form &&
      <><Spacer/><Button isLoading={loading} isDisabled={loading} colorScheme="brand" onClick={onClickPay}>Zahlung mit Klarna abschließen</Button></>}
      {klarnaStatus && !klarnaStatus.show_form && <Error>
        Klarna steht Ihnen aktuell nicht zur Verfügung.
        Bitte versuchen Sie es später erneut oder wenden Sie sich direkt an uns.
      </Error>}
    </div>
  )
};

export default Klarna;