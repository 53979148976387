import React, { useContext } from 'react';

import { StoreContext } from '../context/Store';

const map = {
  '2-3': {
    name: '2 - 3 Werktage',
  },
  '5-7': {
    name: '5 - 7 Werktage',
  },
  toOrder: {
    name: 'Auf Bestellung',
  },
}

const ShippingTime = () => {
  const { settings, currentProduct } = useContext(StoreContext);
  const { shippingTime, reorderPossible, stock } = currentProduct;
  const canReorder = settings.data.purchase_to_order && reorderPossible;
  const custom = !map[shippingTime];

  // TODO Individualisierungen

  if (currentProduct.hasVariants && !currentProduct.variantSelected) {
    return <span>Bitte Variante wählen</span>
  }

  if (stock >= 1) {
    return <span>{custom ? shippingTime : map[shippingTime].name}</span>
  } else if (canReorder) {
    return <span>Auf Bestellung</span>
  } else {
    return <span>Nicht verfügbar</span>
  }
};

export default ShippingTime;