import { useContext }   from 'react';
import { StoreContext } from '../context/Store';

const useIsInCart = (product, variantSelected) => {
  const { cart } = useContext(StoreContext);
  let count;
  let stock;
  let variant;
  if (product) {
    let isInCart;
    if (variantSelected) {
      isInCart = cart.products.filter(cartProduct => cartProduct.product.id === product.id && cartProduct.variant.id === variantSelected.id);
      stock = variantSelected.stock;
    } else {
      isInCart = cart.products.filter(cartProduct => cartProduct.product.id === product.id);
      stock = product.stock;
    }
    if (isInCart.length === 1) {
      count = isInCart[0].count;
      variant = isInCart[0].variant;
    }
    return {
      isInCart: isInCart.length === 1 ? isInCart[0] : false,
      stock,
      count,
      variant,
      loading: false
    }
  } else {
    return {
      isInCart: false,
      count: 0,
      stock: 0,
      variant: 'unset',
      loading: true
    }
  }
}

export default useIsInCart;