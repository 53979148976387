import React from 'react';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Link    from '@interness/web-core/src/components/elements/Link/Link';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

const NotFound = () => {
  return (
    <Wrapper>
      <Spacer />
      <Heading>Nicht gefunden</Heading>
      <p>Diese Seite wurde nicht gefunden.</p>
      <Link to={'/'}>Zurück nach Hause</Link>
    </Wrapper>
  )
};

export default NotFound;