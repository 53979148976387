import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Tag,
  Table,
  Tbody,
  Tr,
  Td,
}                                     from '@chakra-ui/react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import SEO                           from '@interness/web-core/src/components/modules/SEO/SEO';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Gallery         from '@interness/ecommerce-addon/src/components/Gallery/Gallery';
import Error           from '@interness/ecommerce-addon/src/components/Error/Error';
import PriceTag        from '@interness/ecommerce-addon/src/components/PriceTag';
import AddToCartButton from '@interness/ecommerce-addon/src/components/AddToCartButton/AddToCartButton';
import ShippingTime    from '@interness/ecommerce-addon/src/components/ShippingTime';
import Tags            from '@interness/ecommerce-addon/src/components/Tags/Tags';
import Availability    from '@interness/ecommerce-addon/src/components/Availability';
import Personalization from '@interness/ecommerce-addon/src/components/Personalization/Personalization';

import ProductRatings from '../ProductRatings/ProductRatings';
import RatingStars    from '../ProductRatings/RatingStars';
import RequestForm    from '../RequestForm/RequestForm';

const ProductDetails = (props) => {
  const { currentProduct, loading, error, settings } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [shownTabs, setShownTabs] = useState([]);

  const tabs = [
    {
      id: 1,
      displayName: 'Beschreibung',
      isShown: currentProduct.details && currentProduct.details.description,
    },
    {
      id: 2,
      displayName: 'Anfragen',
      isShown: true,
    },
    {
      id: 3,
      displayName: 'Größentabellen',
      isShown: currentProduct.details && currentProduct.details.size_guide,
    },
    {
      id: 4,
      displayName: 'Personalisierung',
      isShown: currentProduct.details && currentProduct.details.personalization,
    },
    {
      id: 5,
      displayName: `Bewertungen ${<Tag ml={2}>{currentProduct.ratingsCount}</Tag>}`,
      isShown: settings && settings.allow_ratings,
    }
  ]

  useEffect(() => {
    if (currentProduct.details) {
      const t = tabs.filter(tab => tab.isShown)
      setShownTabs(t)
    }
  }, [currentProduct])


  if (loading || !currentProduct.details) {
    return <SpinnerContainer><Spinner/></SpinnerContainer>
  }

  if (error) {
    return <Error>Produkt konnte nicht geladen werden.</Error>
  }

  if (!currentProduct.details) {
    return <p>Produkt nicht gefunden</p>
  }

  const handleTabsChange = (i) => {
    setTabIndex(i);
  }

  const onClickRequest = async () => {
    const requestTabIndex = shownTabs.findIndex(tab => tab.id === 2);
    await setTabIndex(requestTabIndex);
    const el = document.getElementById('int-item-request');
    window.scrollTo({
      top: el.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <SEO title={currentProduct.details.display_name}/>
      <Spacer height={30}/>
      <Wrapper>
        <Button colorScheme="brand" variant="outline" onClick={() => window.history.back()}>Zurück</Button>
      </Wrapper>
      <Spacer height={30}/>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Box width={{ base: '100%', lg: '50%' }} pt={[4, 4, 4, 4]} pr={[4, 4, 4, 4]} pb={[4, 4, 4, 0]} pl={[4, 4, 4, 0]} sx={{
          img: {
            margin: '0 auto 0 auto',
          },
        }} bg="base.600">
          <Gallery images={currentProduct.details.images}/>
        </Box>
        <Box width={{ base: '100%', lg: '50%' }} p={4}>
          <Heading style={{ textTransform: 'uppercase' }} size="xl">{currentProduct.details.display_name}</Heading>
          {settings.allow_ratings && currentProduct.ratingsCount !== 0 && currentProduct.averageRating !== 0 &&
          <RatingStars rating={currentProduct.averageRating} count={currentProduct.ratingsCount} showTooltip/>}
          <Spacer height={30}/>
          <Text color="brand.500" fontWeight="bold" fontSize="xl">
            <PriceTag price={currentProduct.priceBrutto}
                      priceRange={currentProduct.variantPriceRange}/>
          </Text>
          <>
            {settings.enable_cart && (!currentProduct.hasVariants && currentProduct.priceBrutto !== 0) || (settings.purchase_to_order && currentProduct.reorderPossible)
              ? <AddToCartButton/>
              : <Button colorScheme="brand" onClick={onClickRequest}>Anfragen</Button>}
          </>
          <Spacer/>
          <Table size="sm">
            <Tbody sx={{
              hyphens: 'auto',
              tr: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}>
              {settings.show_stock && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Verfügbarkeit</b></Td>
                <Td w={['100%', '100%', '50%']}><Availability/></Td>
              </Tr>}
              {/* Shipping Time */}
              {settings.enable_cart && currentProduct.stock >= 0 && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Lieferzeit</b></Td>
                <Td w={['100%', '100%', '50%']}>
                  <ShippingTime/>
                </Td>
              </Tr>}
              {/* Manufacturer */}
              {currentProduct.details.manufacturer && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Kollektion</b></Td>
                <Td w={['100%', '100%', '50%']}>{currentProduct.details.manufacturer ? currentProduct.details.manufacturer.display_name : 'Hersteller unbekannt'}</Td>
              </Tr>}
              {currentProduct.details.tags.length !== 0 && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Schlagwörter</b></Td>
                <Td w={['100%', '100%', '50%']}><Tags tags={currentProduct.details.tags}/></Td>
              </Tr>}
              {/* Attributes */}
              {currentProduct.details.attributes.length > 0 && currentProduct.details.attributes.map(attribute => {
                return (
                  <Tr key={attribute.attribute_group.display_name}>
                    <Td w={['100%', '100%', '50%']}><b>{attribute.attribute_group.display_name}</b></Td>
                    <Td w={['100%', '100%', '50%']}>{attribute.display_values}</Td>
                  </Tr>
                )
              })}
              {currentProduct.details.reference_number && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Referenz</b></Td>
                <Td w={['100%', '100%', '50%']}>{currentProduct.details.reference_number}{currentProduct.variantSelected ? `.${currentProduct.variantSelected.reference_number_suffix}` : null}</Td>
              </Tr>}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <Box pt={4} borderTop="15px solid" borderColor="base.600">
        <Wrapper>
          <Tabs isLazy index={tabIndex} onChange={handleTabsChange} colorScheme="brand" align="center">
            <TabList borderBottomColor="base.600" flexWrap="wrap">
              {shownTabs.map(tab => <Tab key={tab.id}><Heading as="h3" size="sm">{tab.displayName}</Heading></Tab>)}
            </TabList>
            <TabPanels>
              {shownTabs.map(tab => {
                switch (tab.id) {
                  case 1:
                    return (<TabPanel key={tab.id} padding="20px 0 0 0">
                      <Text dangerouslySetInnerHTML={{ __html: currentProduct.details.description }}/>
                    </TabPanel>)
                  case 2:
                    return (<TabPanel key={tab.id} padding="20px 0 0 0">
                      <RequestForm product={currentProduct.details} cta={settings.call_to_action_text}/>
                    </TabPanel>)
                  case 3:
                    return (<TabPanel key={tab.id} padding="20px 0 0 0">
                      <Heading as="h3" size="md">{currentProduct.details.size_guide.display_name}</Heading>
                      <div dangerouslySetInnerHTML={{ __html: currentProduct.details.size_guide.table }}/>
                    </TabPanel>)
                  case 4:
                    return (<TabPanel key={tab.id} padding="20px 0 0 0">
                      <Personalization disabled={currentProduct.priceBrutto !== 0 && !currentProduct.reorderPossible}/>
                    </TabPanel>);
                  case 5:
                    return (<TabPanel key={tab.id} padding="20px 0 0 0">
                      <ProductRatings productId={currentProduct.details.id}/>
                    </TabPanel>)
                  default:
                    return;
                }
              })}
            </TabPanels>
          </Tabs>
          <Spacer/>
        </Wrapper>
      </Box>
    </>
  )
};

ProductDetails.propTypes = {};

export default ProductDetails;