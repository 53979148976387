import React, { useEffect, useContext, useState } from 'react';
import { useQuery }                               from 'react-query';
import { useStaticQuery, graphql }                from 'gatsby';

import { findT } from '@interness/web-core/src/components/_helpers';

import { StoreContext } from '../../context/Store';
import { EApiContext }  from '../../context/EApi';
import ProductDetails   from '../../components/ProductDetails/ProductDetails';

const ProductPage = (props) => {
  const { allDirectusBrands } = useStaticQuery(query);
  const [brandInfo, setBrandInfo] = useState(null);
  const [hasMounted, setHasMounted] = useState(false);

  const { fetchProduct } = useContext(EApiContext);
  const { settings, currentProduct, currentProductDispatcher } = useContext(StoreContext);

  const { data: settingsData } = settings;

  const { isIdle, isLoading, isError, data } = useQuery(['product', {
    id: props.id
  }], fetchProduct, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
    enabled: !!settingsData
  });

  const getAndSetBrandInfo = async (product) => {
    if (!product.manufacturer) {
      return;
    }
    let brand = allDirectusBrands.nodes.filter(({ brand }) => {
      if (brand) {
        return brand.display_name === product.manufacturer.display_name
      }
    })
    if (brand.length === 1) {
      await setBrandInfo(brand[0].brand)
    } else if (brand.length > 1) {
      brand = brand.filter(({ brand }) => {
        const transl = findT(brand.type.translations, 'de');
        return transl.slug === product.category.slug;
      })
      if (brand.length === 1) {
        await setBrandInfo(brand[0].brand)
      }
    }
  };

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }

    if (hasMounted && data) {
      currentProductDispatcher({
        type: 'add',
        payload: data.data
      })

      getAndSetBrandInfo(data.data);
    }

    //eslint-disable-next-line
  }, [hasMounted, data]);

  // Cleanup (unmount)
  useEffect(() => {
    return () => {
      currentProductDispatcher({
        type: 'reset'
      })
    }
    //eslint-disable-next-line
  }, [])

  if (!hasMounted) {
    return null;
  }

  return <ProductDetails
    currentProduct={currentProduct}
    brandInfo={brandInfo}
    loading={isIdle || isLoading}
    error={isError}
    settings={settingsData}
  />
};

export default ProductPage;

const query = graphql`
    query {
        allDirectusBrands {
            nodes {
                brand {
                    display_name
                    slug
                    logo {
                        localFile {
                            name
                            extension
                            publicURL
                            childImageSharp {
                                fixed(width: 240, quality: 100) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    type {
                        type
                        translations {
                            language
                            display_name
                            slug
                        }
                    }
                }
            }
        }
    }
`;