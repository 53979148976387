import React, { useContext, useEffect } from 'react';
import { Flex, Box, useTheme }          from '@chakra-ui/react';
import StepWizard                       from 'react-step-wizard';
import { Heading }                      from '@chakra-ui/react';
import { useQuery }                     from 'react-query';


import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

import Cart                from '../components/Cart/Cart';
import CheckoutInformation from '../components/Checkout/CheckoutInformation';
import CheckoutShipping    from '../components/Checkout/CheckoutShipping';
import CheckoutPayment     from '../components/Checkout/CheckoutPayment';
import CheckoutCheck       from '../components/Checkout/CheckoutCheck';
import { StoreContext }    from '../context/Store';
import CheckoutNav         from '../components/Checkout/CheckoutNav';
import { EApiContext }     from '../context/EApi';
import { CheckoutContext } from '../context/Checkout';
import { readableColor }   from 'polished';

const CheckoutPage = () => {
  const { settings } = useContext(StoreContext);
  const theme = useTheme();
  const { setAvailableShippingMethods } = useContext(CheckoutContext);
  const { fetchShipping } = useContext(EApiContext);

  // const { isLoading, isError, data } = useQuery('shippingMethods', fetchShipping, {
  //   refetchOnWindowFocus: false,
  //   cacheTime: 'Infinity',
  //   staleTime: 'Infinity',
  // });

  // useEffect(() => {
  //   if (data) {
  //     setAvailableShippingMethods(data);
  //   }
  // }, [data])

  // if (isLoading) {
  //   return <SpinnerContainer><Spinner/></SpinnerContainer>
  // }
  //
  // if (isError) {
  //   return <div>error</div>
  // }

  return (
    <Wrapper>
      <Spacer />
      <Flex flexWrap="wrap">
        <Box width={['100%', '100%', '100%', '70%']} sx={{
         'input,select': {
            color: `${readableColor(theme.base_color)} !important`
          }
        }} style={{ overflow: 'hidden' }} pr={[0, 0, 0, 20]}>
          <Heading as="h1">Kasse</Heading>
          <Spacer/>
          {/*Hash conflicts with scroll anchor plugin*/}
          <StepWizard nav={<CheckoutNav/>} isHashEnabled={true} isLazyMount>
            <CheckoutInformation hashKey="informationen" shippingCountries={settings.data.shipping_countries}
                                 presentWrapping={settings.data.present_wrapping}/>
            <CheckoutShipping hashKey="versand" methods={settings.data.shipping_methods}
                              defaultShippingCost={settings.data.shipping_cost}/>
            <CheckoutPayment hashKey="zahlung" methods={settings.data.payment_methods}
                             shippingCountries={settings.data.shipping_countries}/>
            <CheckoutCheck hashKey="check"/>
          </StepWizard>
        </Box>
        <Box width={['100%', '100%', '100%', '30%']}>
          <Box p={15} style={{ position: 'sticky', top: '90px' }}>
            <Heading as="h2">Warenkorb</Heading>
            <Spacer/>
            <Cart checkoutMode/>
          </Box>
        </Box>
      </Flex>
      <Spacer/>
    </Wrapper>
  )
};

export default CheckoutPage;